// Этот файл создан автоматически, генератором кода.
// !!! Версию изменять только в файле package.json !!!
// Синтаксис версий: Semantic Versioning 2.0.0 (https://semver.org/).
//
// This file was automatically generated.
// Version syntax: Semantic Versioning 2.0.0 (https://semver.org/).

const appVersion = '1.0.0+build.20231017.180836.UTC';
export {
  appVersion,
}
