import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import { FileDropModule } from "../../_directives";
import { SpriteIconModule } from "../sprite-icon";
import { FieldsetFileComponent } from './fieldset-file.component';


@NgModule({
  declarations: [
    FieldsetFileComponent,
  ],
  imports: [
    CommonModule,
    FileDropModule,
    NgOptimizedImage,
    SpriteIconModule,
  ],
  exports: [
    FieldsetFileComponent,
  ],
})
export class FieldsetFileModule { }
