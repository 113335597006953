import { ISessionCreateResponse } from "../_services";

/**
 * Ключ данных.
 */
export const TUSER_FEATURE_KEY: string = 'tuser';

/**
 * Интерфейс состояния данных в хранилище NGRX.
 */
export interface TUserState {
  tUser: ISessionCreateResponse | undefined;
}

/**
 * Инициализатор данных в хранилище NGRX.
 */
export const initialTUserState: TUserState = {
  tUser: undefined,
};
