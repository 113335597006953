export const orderNone:string = 'none';
export const orderAsc:string = 'asc';
export const orderDesc:string = 'desc';

/** Настройки колонок заголовка таблицы. */
export interface ITableHead {
  /** Название колонки таблицы. */
  name: string;

  /**
   * Статус сортируемости колонки.
   * Значения:
   * - undefined - Колонка не сортируемая.
   * - none - Колонка сортируемая, состояние: без сортировки.
   * - asc - Колонка сортируемая, состояние: от меньшего к большему.
   * - desc - Колонка сортируемая, состояние: от большего к меньшему.
   */
  sort: string | undefined;

  /**
   * Название поля сортировки при запросе данных с сервера.
   */
  orderName: string;

  /** Сохранённая ширина колонки. */
  width?: number | undefined;

  /** Запрет изменения ширины колонки. Если =истина - изменение ширины запрещена. */
  isNoResize?: boolean | undefined;

  /** Название класса применяемого к заголовку. */
  class?: string | undefined;

  /** Название иконки отображаемой в заголовке. */
  icon?: string | undefined;
}

/** Данные события изменения сортировки в таблице. */
export interface ISortResponse {
  /** Номер колонки в которой инициировано событие изменения сортировки. */
  column: number;

  /** Название поля сортировки при запросе данных с сервера. */
  orderName: string;

  /** Новое значение сортировки в колонке. */
  sort: string | undefined;
}

/** Отображаемые в таблице данные. */
export interface ITableData {
  /** Идентификатор данных, для передачи с событием клика. */
  id: number;
  /** Данные ячейки таблицы. */
  data: string;
}

/** Описание события клик по заголовку. */
export interface ITableHeadClick {
  /** Номер колонки, где произошёл клик. */
  column: number;
}

/** Описание события клик по таблице. */
export interface ITableClick {
  /** Идентификатор данных, берётся из ITableData. */
  id: number;
  /** Номер строки таблицы, где произошёл клик. */
  row: number;
  /** Номер колонки таблицы, где произошёл клик. */
  col: number;
  /** Событие мыши. */
  event: MouseEvent;
}
