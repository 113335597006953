import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import { LogoComponent } from './logo.component';
import { RouterLink } from "@angular/router";


@NgModule({
  declarations: [
    LogoComponent,
  ],
  imports: [
    CommonModule,
    RouterLink,
    NgOptimizedImage,
  ],
  exports: [
    LogoComponent,
  ],
})
export class LogoModule { }
