import { AfterViewInit, Component, ElementRef, EventEmitter, Input, } from '@angular/core';
import { OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ReplaySubject, startWith, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

import {
  ISortResponse,
  ITableClick,
  ITableData,
  ITableHead,
  ITableHeadClick
} from "./table.types";
import { orderAsc, orderDesc, orderNone } from "./table.types";
import { IColumnWidth } from "../../_directives";


/**
 * Компонент динамической таблицы. Все данные и события для таблицы передаются извне.
 * @param head        - Массив колонок таблицы. Тип данных: ITableHead[].
 * @param headUpdate  - Канал обновления колонок. Тип данных: ITableHead[].
 * @param data        - Канал для поступления данных и их обновления. Тип данных: ITableData[][].
 * @param busy        - Канал для поступления состояния "занято" для блокировки таблицы и отображения в момент
 *                      поступления данных в таблицу. Тип данных: boolean.
 * @param noClickable - Флаг запрета клика по телу таблицы.
 * @return outSortResponse        - Канал состояния сортировки колонок таблицы. Тип данных: ISortResponse.
 * @return outColumnWidthResponse - Канал состояния размера колонок таблицы. Тип данных: Map<number, IColumnWidth>.
 * @return outColumnClick         - Канал передачи кликов по заголовку таблицы. Тип данных: ITableHeadClick.
 * @return outClick               - Канал передачи кликов на строки таблицы. Тип данных: ITableClick.
 * @return outViewDone            - Канал передачи события окончания рендеринга тела таблицы.
 */
@Component({
  selector: 'component-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnDestroy, AfterViewInit {
  private readonly resizeBusy$: ReplaySubject<boolean>; // Канал передачи изменения статуса "занято" в директиву.
  private headSubscribe?: Subscription; // Подписка на канал обновления колонок таблицы.
  private dataSubscribe?: Subscription; // Подписка на канал получения данных.
  private busySubscribe?: Subscription; // Подписка на канал получения статуса "занято".
  private lastData: ITableData[][]; // Текущая копия данных отображаемых в таблице.
  private busyState: boolean; // Текущее состояние "занято".

  /** Вариант для получения события окончания выполнения цикла ngFor */
  @ViewChildren('displayExpectationsHead') displayExpectationsHead!: QueryList<ElementRef>;
  @ViewChildren('displayExpectationsData') displayExpectationsData!: QueryList<ElementRef>;
  @ViewChildren('displayExpectationsEmpty') displayExpectationsEmpty!: QueryList<ElementRef>;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('head') public head$!: ITableHead[];
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('headUpdate') public headUpdate$?: ReplaySubject<ITableHead[]> = undefined;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('data') data$!: ReplaySubject<ITableData[][]>;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('busy') busy$!: ReplaySubject<boolean>;
  @Input() noClickable: boolean = false;
  @Output() readonly outSortResponse: EventEmitter<ISortResponse> = new EventEmitter<ISortResponse>();
  @Output() readonly outColumnWidthResponse: EventEmitter<Map<number, IColumnWidth>> = new EventEmitter<Map<number, IColumnWidth>>();
  @Output() readonly outColumnClick: EventEmitter<ITableHeadClick> = new EventEmitter<ITableHeadClick>();
  @Output() readonly outClick: EventEmitter<ITableClick> = new EventEmitter<ITableClick>();
  @Output() readonly outViewDone: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Конструктор.
   */
  constructor() {
    this.resizeBusy$ = new ReplaySubject<boolean>;
    this.lastData = [];
    this.busyState = true;
  }

  /** Инициализатор. */
  ngOnInit(): void {
    if (this.headUpdate$) {
      this.headSubscribe = this.headUpdate$.subscribe((value: ITableHead[]) => this.head$ = value);
    }
    try {
      this.dataSubscribe = this.data$.subscribe((data: ITableData[][]) => this.lastData = data);
    } catch (e: unknown) {
      // console.error('В компонент TableComponent не передан канал данных.');
    }
    try {
      this.busySubscribe = this.busy$.subscribe((busy: boolean): void => {
        this.busyState = busy;
        this.resizeBusy$.next(busy);
      });
    } catch (e: unknown) {
      // console.error('В компонент TableComponent не передан канал "занято".');
    }
  }

  /** Деструктор. */
  ngOnDestroy(): void {
    if (this.headSubscribe) {
      this.headSubscribe.unsubscribe();
      this.headSubscribe = undefined;
    }
    if (this.dataSubscribe) {
      this.dataSubscribe.unsubscribe();
      this.dataSubscribe = undefined;
    }
    if (this.busySubscribe) {
      this.busySubscribe.unsubscribe();
      this.busySubscribe = undefined;
    }
  }

  /** Событие завершения отображения элементов HTML страницы. */
  ngAfterViewInit(): void {
    this.displayExpectationsData.changes
      .pipe(
        startWith(null),
        debounceTime(0),
      )
      .subscribe(() => this.ngForIsDone());
    this.displayExpectationsEmpty.changes
      .pipe(
        startWith(null),
        debounceTime(0),
      )
      .subscribe(() => this.ngForIsDone());
  }

  /** Событие завершения цикла печати строк таблицы. */
  private ngForIsDone(): void {
    this.outViewDone.emit(void {});
  }

  /** Возвращает заголовки таблицы. */
  public get head(): ITableHead[] {
    return this.head$;
  }

  /** Возвращает последний экземпляр данных таблицы. */
  public get data(): ITableData[][] {
    return this.lastData
  }

  /** Возвращает текущее состояние "занято" для таблицы. */
  public get busy(): boolean {
    // this.changeDetectorRef.checkNoChanges();
    return this.busyState;
  }

  /** Возвращает канал состояния "занято" для директивы таблицы изменения размера колонок. */
  public get resizeBusySubject(): ReplaySubject<boolean> {
    return this.resizeBusy$;
  }

  /** Возвращает карту размеров колонок. */
  public get columnWidth(): Map<number, IColumnWidth> | undefined {
    const ret = new Map<number, IColumnWidth>;
    this.head.forEach((v: ITableHead, index: number) => {
      const columnWidth: IColumnWidth = {isNoResize: v.isNoResize, width: v.width};
      ret.set(index, columnWidth);
    });
    return ret;
  }

  /**
   * Функция получает событие клика по заголовку колонки таблицы и переключает сортировку таблицы.
   * @param $event - Событие клика мыши.
   * @param n - Номер колонки по которой кликнули.
   */
  public onClickHead($event: MouseEvent, n: number): void {
    let rsp: ISortResponse = {orderName: this.head[n].orderName, column: n, sort: undefined};
    let hck: ITableHeadClick = {column: n};

    this.outColumnClick.next(hck);
    if (this.busyState) return;
    if (!this.head[n].sort) return;
    switch (this.head[n].sort) {
      case orderAsc:
        this.head[n].sort = orderDesc;
        rsp.sort = this.head[n].sort;
        break;
      default:
        this.head[n].sort = orderAsc;
        rsp.sort = this.head[n].sort;
        break;
    }
    // Сброс состояния сортировки у всех других сортируемых колонок.
    this.head.forEach((_: ITableHead, index: number) => {
      if (index === n) return;
      switch (this.head[index].sort) {
        case undefined:
          return;
        default:
          this.head[index].sort = orderNone;
      }
    });
    this.outSortResponse.next(rsp);
  }

  /**
   * Функция получает события изменения колонок таблицы.
   * @param value - Карта размеров колонок.
   */
  public onColumnResize(value: Map<number, IColumnWidth>): void {
    this.outColumnWidthResponse.next(value);
  }

  /**
   * Функция получает событие клика по телу таблицы.
   * @param $event - Событие клика мыши.
   * @param id     - Идентификатор данных.
   * @param row    - Номер строки.
   * @param col    - Номер колонки.
   */
  public onClickBody($event: MouseEvent, id: number, row: number, col: number): void {
    this.outClick.next({event: $event, id: id, row: row, col: col});
  }
}
