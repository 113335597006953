import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InputButtonFileComponent } from './input-button-file.component';
import { InputButtonRegularModule } from "../input-button-regular";
import { FileDropModule } from "../../_directives";


@NgModule({
  declarations: [
    InputButtonFileComponent,
  ],
  imports: [
    CommonModule,
    InputButtonRegularModule,
    FileDropModule,
  ],
  exports: [
    InputButtonFileComponent,
  ]
})
export class InputButtonFileModule { }
